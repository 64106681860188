import { TextField } from "@mui/material";
import NavigationBar from "../Components/NavigationBar";
import { useState } from "react";
import emailjs from '@emailjs/browser'


function ContactUs() {
    type Sending = {
        name: string,
        details: string,
        message: string
    }

    const [data, setData] = useState<any>({
        name: "",
        details: "",
        message: ""
    })


    const handleChange = (e: any) => {
        console.log(e.target.id);

        if (e.target.id === "name") {
            let holder = { ...data }
            holder.name = e.target.value
            setData(holder)
        } else if (e.target.id === "details") {
            let holder = { ...data }
            holder.details = e.target.value
            setData(holder)
        } else if (e.target.id === "message") {
            let holder = { ...data }
            holder.message = e.target.value
            setData(holder)
        }

    }
    const handleSubmit = async (e: any) => {

        e.preventDefault();
        const serviceId = "service_uul72ck";
        const templateId = "template_xnz7xjk";
        try {

            await emailjs.send(serviceId, templateId, {
                name: data.name,
                details: data.details,
                message: data.message
            }, "SJLSnJZMKm8O_yR5k");
            alert("email successfully sent");
        } catch (error) {
            alert("sorry something went wrong, please try again");
        } finally {
            setData({
                name: "",
                details: "",
                message: ""
            })
        }
    }


    const handleCancel = () => {
        setData({
            name: "",
            details: "",
            message: ""
        })
    }
    return (
        <section className="outer">
            <NavigationBar />
            <div className="inner">
                <div className="headline-container">
                    <h1 >CONTACT US</h1>
                </div>
                <div className="twoColumn">
                    <div className="textbox">
                        <h5>Get In Touch</h5>
                        <p>Please use the form provided to send us any questions you have, we'll try to get back to you as soon as possible</p>
                    </div>

                    <div className="contact-form">
                        <TextField value={data.name} id="name" label={"Name"} required={true} onChange={(e) => handleChange(e)} />
                        <TextField value={data.details} id="details" label={"Contact Details"} required={true} onChange={(e) => handleChange(e)} />

                        <TextField value={data.message} id="message" label={"Message"} multiline
                            rows={3} required={true} onChange={(e) => handleChange(e)} />
                        <div className="btn-container">
                            <button className="btn-cancel" onClick={handleCancel}>Cancel</button>
                            <button className="btn-go" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;