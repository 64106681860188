import React from 'react';

import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Views/Home';
import Tups from './Views/Tups';
import Ewes from './Views/Ewes';
import ContactUs from './Views/ContactUs';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Tups' element={<Tups />} />
          <Route path='/Ewes' element={<Ewes />} />
          <Route path='/Contact' element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
