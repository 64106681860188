import NavigationBar from "../Components/NavigationBar";

function Tups() {
    return (
        <section className="outer">
            <NavigationBar />
            <div className="inner">
                <div className="headline-container">
                    <h1 >TUPS</h1>
                </div>
                <div className="textbox">
                    <h5>Page under Construction</h5>
                </div>
            </div>
        </section>
    );
}

export default Tups;