import NavigationBar from "../Components/NavigationBar";

function Home() {
    return (

        <section className="outer">

            <NavigationBar />
            <div className="inner">
                <div className="headline-container">
                    <h1 >EAST KIRK TEXELS</h1>
                </div>
                <div className="textbox">
                    <h5>Introduction</h5>
                    <p>Located in the far north of Scotland we run a closed Texel flock established in 1999 with 9 PI gimmers</p>
                    <p>We don't use any embryo transfers or AI, Our current stock tups are from the Scrogtonhead & Elmscleugh flocks</p>
                    <p>Lambing takes place from mid March onward as we don't sell any tup lambs, they are all sold as shearlings.
                        We average 150%+ lambings and haven't had any cesarean sections in the last 4 years.
                        The Tups are naturally reared on grass, supplemented with home produced oats, as are the gimmers and ewes.
                    </p>
                    <p>Our goal is to provide shearlings that have length,tight skin, good gigots and are easy lambers </p>
                </div>
            </div>
        </section>


    );
}

export default Home;