import { useLocation, useNavigate } from "react-router-dom"
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { log } from "console";
import { type } from "os";
function NavigationBar() {
    const location = useLocation()
    const active: string = location.pathname
    const navigate = useNavigate()

    type Nav = {
        label: string,
        nav: string
    }

    const navs = [
        {
            label: "Home",
            nav: "/"
        },
        {
            label: "Shearlings",
            nav: "/Tups",
        },
        {
            label: "Gimmers",
            nav: "/Ewes"
        },
        {
            label: "Get In Touch",
            nav: "/Contact"
        }
    ]
    const doNavs = (item: Nav) => {

        navigate(item.nav)

    }
    return (
        <section className="header-outer">

            <div className="header-logo">

            </div>

            {
                navs.map((itm, i) => (
                    <div key={i} className="navBar-item" style={active === itm.nav ? { color: "var(--highlightblue)" } : {}} onClick={() => doNavs(itm)}>
                        <h6 >{itm.label}  </h6>
                        <ArrowForwardIosRoundedIcon />

                    </div>
                ))
            }
            {
                navs.map((itm, i) => (
                    <div key={i} className="navBar-mini" style={active === itm.nav ? { color: "var(--highlightblue)" } : {}} onClick={() => doNavs(itm)}>
                        <h6 >{itm.label}  </h6>


                    </div>
                ))
            }
        </section>
    );
}

export default NavigationBar;